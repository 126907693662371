import React, { useEffect } from 'react';

export default function Stats() {
    const ContactDataBase = process.env.REACT_APP_STATS; 

    useEffect(() => {
        // Define an async function inside useEffect
        const fetchData = async () => {
            if (window.location.hostname !== "localhost") {
                try {
                    const response = await fetch(`${ContactDataBase}`, {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        // Ensure you have defined these variables or pass them as props
                        body: JSON.stringify({ name: 'name', email: 'email', message: 'message' })
                    });
                    if (response.ok) {
                        console.log("Message sent successfully", response);
                    } else {
                        console.error('Error saving data');
                    }
                } catch (error) {
                    console.error('Error:', error);
                }
                console.log('test stats');
            }
        };
        
        fetchData();
    }, []); // Add any dependencies for useEffect here

    return null; // Assuming the component does not render anything
}
