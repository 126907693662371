import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import ReactPixel from 'react-facebook-pixel';

// const PIXEL_ID = 'YOUR_PIXEL_ID';

export default function CookieBanner() {
    const { t } = useTranslation();
    const [visible, setVisible] = useState(false);


    useEffect(() => {
        const consent = localStorage.getItem('cookieConsent');
        if (consent == 'granted') {
            // Replace 'Your-Pixel-ID' with your actual Pixel ID
            ReactPixel.init('697183269215011', null, { autoConfig: true, debug: false });
            // Track page view
            ReactPixel.pageView();
            // Track content
            ReactPixel.track('ViewContent', {
                content_name: 'sunsetbay was oppened',
                content_ids: [1],
                content_type: 'product',
                value: 111,
                currency: 'USD'
              });
        }
        else if (consent == 'rejected') {
            console.log('consent rejected')
        }
        else {
            setVisible(true);
        }
    }, [visible]);


    // useEffect(() => {
    //     const consent = localStorage.getItem('cookieConsent');
    //     if (consent == 'granted') {
    //         window.fbq('consent', 'grant');
    //         window.fbq('init', '697183269215011');
    //         window.fbq('track', 'PageView');
    //         window.fbq('track', 'ViewContent', {
    //             content_name: 'Main Page',
    //         });
    //         // console.log('count facebook')
    //     }
    //      else if (consent == 'rejected') {
    //         window.fbq('consent', 'revoke');
    //     //     // window.fbq('init', '697183269215011');
    //     //     // window.fbq('track', 'PageView');
    //         // console.log('count facebook 2')
    //     }
    //     else {
    //         setVisible(true);
    //         window.fbq('consent', 'revoke');
    //         // window.fbq('init', '697183269215011');
    //         // window.fbq('track', 'PageView');
    //         // console.log('count facebook 3')
    //     }
    // }, [visible]);

    const handleAccept = () => {
        localStorage.setItem('cookieConsent', 'granted');
        setVisible(false);
    };

    const handleReject = () => {
        localStorage.setItem('cookieConsent', 'rejected');
        setVisible(false);
    };

    if (!visible) return null;

    return (
        <div className='CookieBanner'>
            <div className='text'>
                <div className='title'>{t('cookies.title')}</div>
                <div>{t('cookies.text')}<Link className="cookiesLink" to="cookies">Cookies Policy</Link>.</div>
            </div>
            <div className='buttons'>
                <button onClick={handleAccept}>{t('cookies.accept')}</button>
                <button onClick={handleReject}>{t('cookies.reject')}</button>
            </div>
        </div>
    )
}
